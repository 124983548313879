/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { Box, Button, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  startGameCounter,
  stopGameCounter,
  endGame,
  endGameWinner,
  endGameLoser,
  removeLosersFromTeamBoard,
  resetScore,
  setPlayerTeam,
  setPointsHistory,
} from 'redux/slices';

export function StartGameBtn() {
  const {
    gameStarted, scoreA, scoreB, playersPerTeam,
  } = useSelector((state) => state.game);
  const players = useSelector((state) => state.players);
  const teams = useSelector((state) => state.teams);
  const dispatch = useDispatch();

  const teamA = players.filter((p) => p.teamId === 1);
  const teamB = players.filter((p) => p.teamId === 2);

  const teamsHavePlayers = teamA.length === playersPerTeam && teamB.length === playersPerTeam;
  const scoreIsZero = scoreA === 0 && scoreB === 0;

  const teamThatWinLastGame = teams.find((t) => t.id === 1)?.winLastGame ? 'A' : 'B';

  const handleStartGame = () => {
    dispatch(startGameCounter());
  };

  const generateTeam = () => {
    const playersWaiting = players.filter((p) => p.teamId === 0 && p.checkedIn);

    const maxWaitingFor = Math.max(...playersWaiting.map((p) => p.waitingFor), 0);

    const candidates = playersWaiting.filter((p) => p.waitingFor === maxWaitingFor);

    const teamAPlayers = players.filter((p) => p.teamId === 1).length;
    const teamBPlayers = players.filter((p) => p.teamId === 2).length;
    const teamToFill = teamAPlayers < teamBPlayers ? 1 : 2;

    const targetTeamId = teamAPlayers === teamBPlayers ? teamToFill : (teamAPlayers < teamBPlayers ? 1 : 2);

    candidates.slice(0, playersPerTeam - (targetTeamId === 1 ? teamAPlayers : teamBPlayers)).forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: targetTeamId }));
    });
  };

  const handleEndGame = () => {
    dispatch(stopGameCounter());
    const WINNER = scoreA > scoreB ? 1 : 2;
    const LOSER = scoreA > scoreB ? 2 : 1;
    dispatch(endGameWinner({ teamId: WINNER }));
    dispatch(endGameLoser({ teamId: LOSER }));
    dispatch(endGame({ teamId: WINNER }));
    dispatch(removeLosersFromTeamBoard({ teamId: LOSER }));
    dispatch(setPointsHistory([`${WINNER === 1 ? 'A' : 'B'}`]));

    generateTeam();
  };

  return (
    <Box sx={{ margin: '20px auto', width: 300 }} mt={1} display="flex" justifyContent="center" flexDirection="column">
      <Typography variant="h6" align="center" mb={3}>
        {scoreIsZero ? `A bola começa com o time ${teamThatWinLastGame} !` : ''}
      </Typography>
      {
        !gameStarted
          ? <Button disabled={!teamsHavePlayers} variant="contained" onClick={handleStartGame}>Começar Jogo</Button>
          : <Button variant="contained" color="secondary" onClick={handleEndGame}>Finalizar Jogo</Button>
      }
      <br />
      <Button disabled={scoreIsZero} variant="contained" color="error" onClick={() => dispatch(resetScore())}>Resetar Placar</Button>
    </Box>
  );
}
