import {
  Select, MenuItem, Typography, Box,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePlayersPerTeam } from 'redux/slices';

export function SelectNumberOfPlayersPerTeam() {
  const { playersPerTeam } = useSelector((state) => state.game);
  const dispatch = useDispatch();
  const options = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography>Jogadores por time</Typography>
      <Select
        value={playersPerTeam}
        size="small"
        onChange={(e) => dispatch(changePlayersPerTeam(e.target.value))}
      >
        {
        options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
            {' '}
            Jogadores
          </MenuItem>
        ))
      }
      </Select>
    </Box>
  );
}
