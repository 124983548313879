import { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createNewPlayer } from 'redux/slices';

export function AddPlayer() {
  const [playerName, setPlayerName] = useState('');
  const players = useSelector((state) => state.players);
  console.log(players);
  const dispatch = useDispatch();

  const handleForm = (e) => {
    e.preventDefault();
    if (!playerName) return alert('Preencha o nome do jogador');
    const findPlayer = players.find((player) => player.playerName === playerName);
    if (findPlayer) return alert('Jogador já cadastrado');
    const initialWins = prompt('Peso inicial do jogador');
    const newPlayer = {
      playerId: players.length ? players[players.length - 1].playerId + 1 : 1,
      playerName,
      wins: Number(initialWins) || 0,
    };
    setPlayerName('');
    return dispatch(createNewPlayer(newPlayer));
  };

  return (
    <form onSubmit={handleForm}>
      <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
        <TextField
          size="small"
          value={playerName}
          onChange={(e) => setPlayerName(e.target.value)}
          id="player"
          label="Nome do jogador"
        />
        <Button variant="contained" onClick={handleForm}>ADD</Button>
      </Box>
    </form>
  );
}
