import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import WaitingPlayerCard from './WaitingPlayerCard';

export function PlayersQueue() {
  const players = useSelector((state) => state.players);
  const [playersQueue, setPlayersQueue] = useState([]);

  useEffect(() => {
    const getPlayersQueue = players.filter((p) => !p.isPlaying && p.checkedIn)
      .sort((a, b) => b.waitingFor - a.waitingFor);

    setPlayersQueue(getPlayersQueue);
  }, [players]);

  return (
    <Box
      sx={{
        mt: 4,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" textAlign="center">
        {`${playersQueue.length} jogadores em espera:`}
      </Typography>
      <Box
        sx={{
          mt: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {playersQueue.length > 0
          ? playersQueue
            .map((player) => (
              <WaitingPlayerCard key={`${player.playerId}-${player.playerName}`} player={player} />
            ))
          : 'Nenhum jogador em espera'}
      </Box>
    </Box>
  );
}
