/* eslint-disable max-len */
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CurrentPlayerCard from './CurrentPlayerCard';
import { GenerateBalancedTeamBtn } from './GenerateBalancedTeamBtn';
import { GenerateTwoTeamsBtn } from './GenerateTwoTeamsBtn';

export function CurrentPlayers() {
  const players = useSelector((state) => state.players);
  const teamAMembers = players.filter((p) => p.teamId === 1);
  const teamBMembers = players.filter((p) => p.teamId === 2);

  const noPlayersInTeamA = teamAMembers.length === 0;
  const noPlayersInTeamB = teamBMembers.length === 0;

  const noPlayersInBothTeams = noPlayersInTeamA && noPlayersInTeamB;

  const totalValueTeamA = teamAMembers.reduce((acc, curr) => acc + (curr.wins - curr.losses), 0);
  const totalValueTeamB = teamBMembers.reduce((acc, curr) => acc + (curr.wins - curr.losses), 0);

  const playerTypeOrder = {
    Both: 1,
    Attacker: 2,
    Setter: 3,
    Flex: 4,
  };

  const sortTeamMembers = (teamMembers) => teamMembers.sort((a, b) => playerTypeOrder[a.playerType] - playerTypeOrder[b.playerType]);

  const teamA = sortTeamMembers(teamAMembers);
  const teamB = sortTeamMembers(teamBMembers);

  return (
    noPlayersInBothTeams
      ? (
        <Box
          sx={{
            mt: 4,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <GenerateTwoTeamsBtn />
        </Box>
      )
      : (
        <Box
          sx={{
            mt: 4,
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginRight: 2,
            }}
          >
            <Typography variant="h5" textAlign="center">
              Time A (
              {totalValueTeamA}
              )
            </Typography>
            {teamA.map((player) => (
              <CurrentPlayerCard key={`${player.playerId}-${player.playerName}`} player={player} />
            ))}
            {noPlayersInTeamA && (<GenerateBalancedTeamBtn />)}
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginRight: 2,
            }}
          >
            <Typography variant="h5" textAlign="center">
              Time B (
              {totalValueTeamB}
              )
            </Typography>
            {teamB.map((player) => (
              <CurrentPlayerCard key={`${player.playerId}-${player.playerName}`} player={player} />
            ))}
            {noPlayersInTeamB && (<GenerateBalancedTeamBtn />)}
          </Box>
        </Box>
      )
  );
}
