/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { Button } from '@mui/material';
import { setPlayerTeam } from 'redux/slices';
import { useSelector, useDispatch } from 'react-redux';

export function GenerateBalancedTeamBtn() {
  const players = useSelector((state) => state.players);
  const playersPerTeam = useSelector((state) => state.game.playersPerTeam);
  const dispatch = useDispatch();

  const generateTeam = () => {
    const playersWaiting = players.filter((p) => p.teamId === 0 && p.checkedIn);

    const candidates = playersWaiting
      .sort((a, b) => b.waitingFor - a.waitingFor)
      .reduce((acc, player) => {
        if (acc.length === 0 || acc[acc.length - 1][0].waitingFor !== player.waitingFor) {
          acc.push([player]);
        } else {
          acc[acc.length - 1].push(player);
        }
        return acc;
      }, [])
      .flatMap((group) => group.sort(() => 0.5 - Math.random()));

    const teamAPlayers = players.filter((p) => p.teamId === 1).length;
    const teamBPlayers = players.filter((p) => p.teamId === 2).length;
    const teamToFill = teamAPlayers < teamBPlayers ? 1 : 2;

    const targetTeamId = teamAPlayers === teamBPlayers ? teamToFill : (teamAPlayers < teamBPlayers ? 1 : 2);

    candidates.slice(0, (playersPerTeam) - (targetTeamId === 1 ? teamAPlayers : teamBPlayers)).forEach((player) => {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId: targetTeamId }));
    });
  };

  return (
    <Button variant="contained" onClick={generateTeam}>
      Próximos jogadores
    </Button>
  );
}
