import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState([])('players');

export const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    createNewPlayer: (state, action) => {
      const { playerId, playerName, wins } = action.payload;
      state.push({
        playerId,
        playerName,
        wins,
        losses: 0,
        teamId: 0,
        isPlaying: false,
        waitingFor: 0,
        checkedIn: true,
        playerType: 'Both', // flex, setter, attacker
      });
    },
    setPlayerTeam: (state, action) => {
      const { playerId, teamId } = action.payload;
      const player = state.find((p) => p.playerId === playerId);
      player.teamId = teamId;
      player.isPlaying = true;
    },
    addWaitingFor: (state, action) => {
      const playerId = action.payload;
      const player = state.find((p) => p.playerId === playerId);
      player.waitingFor += 1;
    },
    playerReady: (state, action) => {
      const playerId = action.payload;
      const player = state.find((p) => p.playerId === playerId);
      player.isPlaying = true;
    },
    removePlayer: (state, action) => {
      const playerId = action.payload;
      const player = state.find((p) => p.playerId === playerId);
      player.isPlaying = false;
      player.waitingFor = 0;
      player.teamId = 0;
    },
    endGame: (state, action) => state.map((player) => ({
      ...player,
      waitingFor: player.isPlaying ? 0 : player.waitingFor + 1,
      isPlaying: action.payload.teamId === player.teamId,
      wins: action.payload.teamId === player.teamId ? player.wins + 1 : player.wins,
      losses: action
        .payload.teamId !== player.teamId
        && player.teamId !== 0 ? player.losses + 1 : player.losses,
      teamId: action.payload.teamId !== player.teamId ? 0 : player.teamId,
    })),
    checkIn: (state, action) => {
      const playerId = action.payload;
      const player = state.find((p) => p.playerId === playerId);
      player.checkedIn = true;
    },
    checkOut: (state, action) => {
      const playerId = action.payload;
      const player = state.find((p) => p.playerId === playerId);
      player.checkedIn = false;
      player.waitingFor = 0;
    },
    checkOutAllPlayers: (state) => state.forEach((player) => {
      player.checkedIn = false;
      player.waitingFor = 0;
    }),
    removeAllPlayersFromTeamBoard: (state) => {
      state.forEach((player) => {
        player.teamId = 0;
        player.isPlaying = false;
      });
    },
    swapTeamIdsForAllPlayers: (state) => {
      state.forEach((player) => {
        if (player.teamId === 1) {
          player.teamId = 2;
        } else if (player.teamId === 2) {
          player.teamId = 1;
        }
      });
    },
    swapTeamIdBetween2Players: (state, action) => {
      const { playerId1, playerId2 } = action.payload;
      const player1 = state.find((p) => p.playerId === playerId1);
      const player2 = state.find((p) => p.playerId === playerId2);
      const tempTeamId = player1.teamId;
      player1.teamId = player2.teamId;
      player2.teamId = tempTeamId;
    },
    deletePlayer: (state, action) => {
      const playerId = action.payload;
      return state.filter((p) => p.playerId !== playerId);
    },
    addWin: (state, action) => {
      const playerId = action.payload;
      const player = state.find((p) => p.playerId === playerId);
      player.wins += 1;
    },
    addLoss: (state, action) => {
      const playerId = action.payload;
      const player = state.find((p) => p.playerId === playerId);
      player.losses += 1;
    },
    changePlayerType: (state, action) => {
      const { playerId, playerType } = action.payload;
      const player = state.find((p) => p.playerId === playerId);
      player.playerType = playerType;
    },
  },
});

export const {
  addWaitingFor,
  createNewPlayer,
  setPlayerTeam,
  playerReady,
  removePlayer,
  endGame,
  checkIn,
  checkOut,
  checkOutAllPlayers,
  removeAllPlayersFromTeamBoard,
  swapTeamIdsForAllPlayers,
  swapTeamIdBetween2Players,
  deletePlayer,
  addWin,
  addLoss,
  changePlayerType,
} = playersSlice.actions;
