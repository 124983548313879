import {
  Box, Divider, Typography,
} from '@mui/material';
import { CheckInPlayer } from './CheckInPlayer';
import { DeletePlayer } from './DeletePlayer';
import { UpdatePlayerValue } from './UpdatePlayerValue';

export default function OfflinePlayerCard({
  player,
  position,
  ranking,
  isAdmin,
}) {
  return (
    <Box sx={{
      width: { xs: '100%', md: '80%' },
    }}
    >
      <Box
        key={`${player.playerId}-${player.playerName}`}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          borderRadius: '5px',
          padding: '10px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >

          <Typography variant="h6">
            {position && ranking ? `${position}. ` : ''}
            {player.playerName}
            {' '}
            (
            {player.wins - player.losses}
            )
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          {!isAdmin && <CheckInPlayer playerId={player.playerId} />}
          {isAdmin && <DeletePlayer playerId={player.playerId} />}
          {isAdmin && <UpdatePlayerValue playerId={player.playerId} />}
        </Box>
      </Box>
      <Divider variant="fullWidth" />
    </Box>
  );
}
