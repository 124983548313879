import { createSlice } from '@reduxjs/toolkit';
import { defineState } from 'redux-localstore';

const initialState = defineState([
  {
    id: 1,
    name: 'Time A',
    score: 0,
    isPlaying: false,
    wins: 0,
    winLastGame: false,
    winStrike: 0,
    players: [],
  },
  {
    id: 2,
    name: 'Time B',
    score: 0,
    isPlaying: false,
    wins: 0,
    winLastGame: false,
    winStrike: 0,
    players: [],
  },
])('teams');

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    addScore: (state, action) => {
      const { teamId, score } = action.payload;
      const team = state.find((t) => t.id === teamId);
      team.score += score;
    },
    removeScore: (state, action) => {
      const { teamId, score } = action.payload;
      const team = state.find((t) => t.id === teamId);
      team.score -= score;
    },
    createNewTeam: (state, action) => {
      const { teamId } = action.payload;
      state.push({
        id: teamId,
        score: 0,
        isPlaying: false,
        wins: 0,
        winLastGame: false,
      });
    },
    startGame: (state, action) => {
      const { teamId } = action.payload;
      const team = state.find((t) => t.id === teamId);
      team.isPlaying = true;
    },
    endGameWinner: (state, action) => {
      const { teamId } = action.payload;
      const team = state.find((t) => t.id === teamId);
      team.wins += 1;
      team.winLastGame = true;
      team.winStrike += 1;
      team.score = 0;
    },
    endGameLoser: (state, action) => {
      const { teamId } = action.payload;
      const team = state.find((t) => t.id === teamId);
      team.winLastGame = false;
      team.score = 0;
      team.winStrike = 0;
      team.isPlaying = false;
    },
    resetTeamStats: (state, action) => {
      const { teamId } = action.payload;
      const team = state.find((t) => t.id === teamId);
      team.score = 0;
      team.wins = 0;
      team.winLastGame = false;
      team.players = [];
    },
    resetAllTeamsStats: (state) => {
      state.forEach((team) => {
        team.score = 0;
        team.wins = 0;
        team.winLastGame = false;
        team.winStrike = 0;
        team.players = [];
      });
    },
    addPlayerToTeam: (state, action) => {
      const { teamId, playerId } = action.payload;
      const team = state.find((t) => t.id === teamId);
      team.players.push(playerId);
    },
    removePlayerFromTeam: (state, action) => {
      const { teamId, playerId } = action.payload;
      const team = state.find((t) => t.id === teamId);
      const index = team.players.indexOf(playerId);
      team.players.splice(index, 1);
    },
    removeLosersFromTeamBoard: (state, action) => {
      const { teamId } = action.payload;
      const team = state.find((t) => t.id === teamId);
      team.players = [];
    },
    removeAllPlayersFromTeams: (state) => {
      state.forEach((team) => {
        team.players = [];
      });
    },
    swapPlayersBetweenTeams: (state) => {
      const team1 = state.find((t) => t.id === 1);
      const team2 = state.find((t) => t.id === 2);

      if (team1 && team2) {
        [team1.players, team2.players] = [team2.players, team1.players];
      }

      const winTeam1 = team1.winLastGame;
      const winTeam2 = team2.winLastGame;

      const winStrikeTeam1 = team1.winStrike;
      const winStrikeTeam2 = team2.winStrike;

      team1.winLastGame = winTeam2;
      team2.winLastGame = winTeam1;

      team1.winStrike = winStrikeTeam2;
      team2.winStrike = winStrikeTeam1;
    },
    changePlayerTeam: (state, action) => {
      const { playerId1, playerId2 } = action.payload;
      const team1 = state.find((t) => t.id === 1);
      const team2 = state.find((t) => t.id === 2);

      if (team1 && team2) {
        const index1 = team1.players.indexOf(playerId1);
        team1.players.splice(index1, 1);
        const index2 = team2.players.indexOf(playerId2);
        team2.players.splice(index2, 1);
        team1.players.push(playerId2);
        team2.players.push(playerId1);
      }
    },

  },
});

export const {
  addScore,
  removeScore,
  startGame,
  endGameWinner,
  endGameLoser,
  removePlayerFromTeam,
  addPlayerToTeam,
  resetTeamStats,
  resetAllTeamsStats,
  removeLosersFromTeamBoard,
  removeAllPlayersFromTeams,
  swapPlayersBetweenTeams,
  changePlayerTeam,
} = teamsSlice.actions;
