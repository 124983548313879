/* eslint-disable no-nested-ternary */
import {
  Box, Button, Divider, Typography,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayerTeam, addPlayerToTeam } from 'redux/slices';

import { RemovePlayer } from './RemovePlayer';

export default function WaitingPlayerCard({ player }) {
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.players);
  const playersPerTeam = useSelector((state) => state.game.playersPerTeam);

  const handleAddPlayer = () => {
    const isTeamAFull = teams.filter((p) => p.teamId === 1).length === playersPerTeam;
    const isTeamBFull = teams.filter((p) => p.teamId === 2).length === playersPerTeam;
    const teamId = isTeamAFull ? 2 : 1;

    if (!isTeamAFull || !isTeamBFull) {
      dispatch(setPlayerTeam({ playerId: player.playerId, teamId }));
      return dispatch(addPlayerToTeam({ playerId: player.playerId, teamId }));
    } return alert('Não há times disponíveis');
  };

  return (
    <Box sx={{
      width: { xs: '100%', md: '80%' },
    }}
    >
      <Box
        key={`${player.playerId}-${player.playerName}`}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          borderRadius: '5px',
          padding: '10px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Typography variant="h6">
            {player.playerName}
            (
            {player.waitingFor}
            )
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={handleAddPlayer}
          >
            Jogar
          </Button>
          <RemovePlayer playerId={player.playerId} />

        </Box>
      </Box>
      <Divider variant="fullWidth" />
    </Box>
  );
}
